import React from 'react';

const PropertyTaxArticle = () => {
  return (
    <div className="container py-4">
      <h1 className="display-4 mb-4" style={{ color: '#2C3E50' }}>
        Understanding Jersey City Property Taxes in 2024
      </h1>
      
      <div className="row mb-5">
        <div className="col-12">
          <p className="lead">
            Property taxes in Jersey City continue to be a significant consideration for homeowners and potential buyers. 
            This comprehensive guide covers current rates, exemptions, and the appeals process.
          </p>
        </div>
      </div>

      <section className="mb-5">
        <h2 className="h3 mb-3" style={{ color: '#34495E' }}>Current Tax Rates and Future Outlook</h2>
        
        <div className="row">
          <div className="col-md-8">
            <p>
              The current effective tax rate in Jersey City is approximately 1.48% of assessed value for 2024. 
              <a href='/property_tax_guides/nj/hudson_county' target='_blank'>Hudson County</a> maintains one of New Jersey's highest property tax rates, reflecting the area's 
              strong public services and school system funding needs.
            </p>
            <p>
              Recent city council discussions have indicated potential modest increases in the coming fiscal year 
              to support infrastructure improvements and educational initiatives.
            </p>
          </div>
          
          <div className="col-md-4">
            <div className="card border-primary">
              <div className="card-header bg-primary text-white">
                Sample Tax Calculation
              </div>
              <div className="card-body">
                <p className="card-text">
                  Property Value: $500,000<br />
                  Tax Rate: 1.48%<br />
                  <strong>Annual Tax: $7,400</strong><br />
                  Monthly Payment: $616.67
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-5">
        <h2 className="h3 mb-3" style={{ color: '#34495E' }}>Tax Exemptions and Benefits</h2>
        
        <div className="row g-4">
          <div className="col-md-6">
            <div className="card h-100">
              <div className="card-body">
                <h3 className="h5 card-title text-primary">Veterans Benefits</h3>
                <ul className="list-unstyled">
                  <li className="mb-2">✓ 100% Disabled Veterans receive full property tax exemption</li>
                  <li className="mb-2">✓ Standard veteran deduction: $250 annually</li>
                  <li className="mb-2">✓ Surviving spouse continuation of benefits</li>
                  <li className="mb-2">✓ Post-9/11 service members eligible for additional benefits</li>
                </ul>
              </div>
            </div>
          </div>
          
          <div className="col-md-6">
            <div className="card h-100">
              <div className="card-body">
                <h3 className="h5 card-title text-primary">Senior Citizen Programs</h3>
                <ul className="list-unstyled">
                  <li className="mb-2">✓ Age requirement: 65 years or older</li>
                  <li className="mb-2">✓ Income limit: $92,969 annually</li>
                  <li className="mb-2">✓ Property tax deduction: Up to $250 annually</li>
                  <li className="mb-2">✓ Tax freeze program available for eligible seniors</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-5">
        <h2 className="h3 mb-4" style={{ color: '#34495E' }}>Tax Appeals and Success Stories</h2>
        
        <div className="row">
          <div className="col-12">
            <p>
              In the past year, approximately 2,500 Jersey City property owners filed tax appeals, 
              with about 40% achieving successful outcomes. The average reduction in assessed value 
              for successful appeals was approximately $95,000.
            </p>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-body">
            <h3 className="h5 text-primary">Recent Success Story: Downtown Property Owner</h3>
            <p>
              In late 2023, a Downtown Jersey City resident successfully appealed their property assessment, 
              resulting in a $200,000 reduction in assessed value. The property owner, who wished to remain 
              anonymous, provided evidence of comparable sales in their neighborhood to support their case. 
              The appeal process took three months and resulted in annual tax savings of $3,800.
            </p>
            <p className="mb-0">
              "The key was gathering comprehensive data about similar properties in my neighborhood that had 
              sold recently," the homeowner shared. "The appeal process was straightforward, though it 
              required patience and attention to detail."
            </p>
          </div>
        </div>
      </section>

      <footer className="border-top pt-4">
        <div className="row">
          <div className="col-12">
            <p className="text-muted">
              <small>Information updated as of 2024. Tax rates and exemption criteria are subject to change. 
              For the most current information, please contact the Jersey City Tax Assessor's Office. 
              Tax appeal deadline is April 1st or 45 days from assessment notice receipt, whichever is later.</small>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PropertyTaxArticle;