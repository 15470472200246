import { Button, Container } from 'library';
import React, { Component } from 'react';

export default class BlogCTA extends Component {
    render() {
        return (
            <div className='container'>
            <div class="card">
                <div class="card-body">
                    <h3 class="h5 text-success mb-3">Lower Your Property Taxes</h3>
                    <p>We know that property taxes are complicated. We can help.</p>
                    <p>We prepare all the legal documents, find comparables and make sure you properly apply for all exemptions.</p>
                    <p>And we guarantee you save money or we don't get paid.</p>
                    <Button href='/sign_up/' type='success'>Lower Your Property Taxes</Button>
                </div>
            </div>
            </div>
        )
    }
}


