import React, { Component } from 'react';
import { Alert } from 'library';
import { Address } from 'pages';

import { DOLLAR_STYLING } from 'constants';

export default class PropertyDetailsCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let property = this.props.property;
        let evaluation = property.tax_evaluation;

        let current_total =
            evaluation.building_assessment +
            evaluation.land_assessment +
            evaluation.exemption;

        return (
            <div className="simple-card full-height">
                <Address property={property} vertical={true} />
                <br />
                <table className="table">
                    <Alert type="info" style={{ margin: '10px 0px 0px 0px' }}>
                        <div style={{ fontWeight: 'bold' }}>
                            Property Details
                        </div>
                    </Alert>
                    <tbody>
                        <tr>
                            <td>
                                <b>Neighborhood:</b> {property.neighborhood}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Year Built:</b> {property.year_built}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Sq. Ft.:</b> {property.sqft}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Acreage:</b> {property.acreage}
                            </td>
                        </tr>

                        <tr>
                            <th>Property Tax Details</th>
                        </tr>
                        <tr>
                            <td>
                                <b>$/Sq. Ft.:</b>{' '}
                                {(
                                    evaluation.building_assessment /
                                    property.sqft
                                ).toLocaleString('en-US', DOLLAR_STYLING)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>$/Acre:</b>{' '}
                                {(
                                    evaluation.land_assessment /
                                    property.acreage
                                ).toLocaleString('en-US', DOLLAR_STYLING)}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Alert type="info" style={{ margin: '10px 0px 0px 0px' }}>
                    <div style={{ fontWeight: 'bold' }}>Current Assessment</div>
                </Alert>
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <b>Building Assessed Value:</b>{' '}
                                {evaluation.building_assessment.toLocaleString(
                                    'en-US',
                                    DOLLAR_STYLING,
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Land Assessed Value:</b>{' '}
                                {evaluation.land_assessment.toLocaleString(
                                    'en-US',
                                    DOLLAR_STYLING,
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Exemption:</b>{' '}
                                {evaluation.exemption.toLocaleString(
                                    'en-US',
                                    DOLLAR_STYLING,
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Total Assessed Value:</b>{' '}
                                {current_total.toLocaleString(
                                    'en-US',
                                    DOLLAR_STYLING,
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Yearly Taxes:</b>{' '}
                                {property.current_taxes.toLocaleString(
                                    'en-US',
                                    DOLLAR_STYLING,
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
