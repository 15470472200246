import React, { Component } from 'react';
import { ajax_wrapper, get_global_state } from 'functions';
import { Loading, Container } from 'library';

import PropertyDetailsCard from './property_details/property_details_card';
import ComparablePropertyDetailsCard from './property_details/comparable_property_details_card';
import TotalDetailsCard from './property_details/total_details_card';

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            property: {
                tax_evaluation: {
                    building_assessment: 0,
                    land_assessment: 0,
                    exemption: 0,
                },
                current_taxes: 0,
            },
            comparables: [],
            current_comparable: {
                comparable_building_assessment: 0,
                comparable_land_assessment: 0,
                comparable_property: {
                    tax_evaluation: {
                        building_assessment: 0,
                        land_assessment: 0,
                        exemption: 0,
                    },
                    current_taxes: 0,
                },
            },
            comparable_index: 0,
            loaded: false,
        };
    }

    componentDidMount() {
        this.get_property();
    }

    get_property = () => {
        let params = get_global_state()['params'];
        localStorage.setItem('property_id', params['property_id']);
        ajax_wrapper(
            'GET',
            '/api/property_insights/?property_id=' + params['property_id'],
            {},
            this.load_property,
        );
    };

    load_property = (data) => {
        data['loaded'] = true;
        data['current_comparable'] = data['comparables'][0];
        this.setState(data);
    };

    end_animation = () => {};

    render() {
        let next = null;
        if (this.state.comparable_index < this.state.comparables.length - 1) {
            next = (
                <a
                    class="carousel-control-next"
                    role="button"
                    data-slide="next"
                    onClick={() =>
                        this.setState(
                            {
                                comparable_index:
                                    this.state.comparable_index + 1,
                                animate_out: this.state.comparable_index,
                                animate_in: this.state.comparable_index + 1,
                            },
                            this.end_animation,
                        )
                    }
                    style={{
                        backgroundImage:
                            'linear-gradient(90deg, transparent, rgba(0, 0, 0, .3))',
                    }}
                >
                    <i
                        class="bi bi-caret-right-fill"
                        style={{ marginTop: '6rem', fontSize: '2rem' }}
                    ></i>
                </a>
            );
        }

        let prev = null;
        if (this.state.comparable_index > 0) {
            prev = (
                <a
                    class="carousel-control-prev"
                    role="button"
                    data-slide="prev"
                    onClick={() =>
                        this.setState(
                            {
                                comparable_index:
                                    this.state.comparable_index - 1,
                                animate_out: this.state.comparable_index,
                                animate_in: this.state.comparable_index - 1,
                            },
                            this.end_animation,
                        )
                    }
                    style={{
                        backgroundImage:
                            'linear-gradient(90deg, rgba(0, 0, 0, .3), transparent)',
                    }}
                >
                    <i
                        class="bi bi-caret-left-fill"
                        style={{ marginTop: '6rem', fontSize: '2rem' }}
                    ></i>
                </a>
            );
        }

        let comps = [];
        let index = 0;
        for (let comp of this.state.comparables) {
            let active = this.state.comparable_index == index ? 'active' : '';

            let animating =
                this.state.animate_in != null && this.state.animate_out != null;
            let animation_classes = '';
            if (animating) {
                /* Do some class work here */
            }

            comps.push(
                <div
                    class={`carousel-item ${active} ${animation_classes}`}
                    style={{ height: '100%' }}
                >
                    <ComparablePropertyDetailsCard
                        property={this.state.property}
                        comparable={comp}
                        style={{ margin: '0px' }}
                    />
                </div>,
            );

            index += 1;
        }

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <div className="row">
                        <div
                            className="col-md-4 col-sm-12"
                            style={{ marginBottom: '20px' }}
                        >
                            <PropertyDetailsCard
                                property={this.state.property}
                            />
                        </div>
                        <div
                            className="col-md-4 col-sm-6"
                            style={{ marginBottom: '20px' }}
                        >
                            <div
                                class="carousel slide full-height"
                                data-ride="carousel"
                            >
                                <div class="carousel-inner full-height">
                                    {comps}
                                </div>
                                {prev}
                                {next}
                            </div>
                        </div>
                        <div
                            className="col-md-4 col-sm-6"
                            style={{ marginBottom: '20px' }}
                        >
                            <TotalDetailsCard
                                property={this.state.property}
                                comparable={
                                    this.state.comparables[
                                        this.state.comparable_index
                                    ]
                                }
                            />
                        </div>
                    </div>
                </Loading>
            </Container>
        );
    }
}
