import React, { Component } from 'react';

export default class Home extends Component {
    constructor(props) {
        super(props);
    }


    render() {

        return (
            <div>
                <h1>Stop Overpaying For Property Taxes</h1>
            </div>
        );
    }
}
