import React, { Component } from 'react';

import { ajax_wrapper } from 'functions';
import { Form, TextInput, Container, Alert, Button } from 'library';

// GOOGLE OAUTH USES THIS DOCUMENTATION!!!!
// https://developers.google.com/identity/oauth2/web/guides/use-token-model
const GOOGLE_CLIENT_ID =
    '531056339342-q2dif28ma2pt7c795qrom5muttv6cg8g.apps.googleusercontent.com';

class SignUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            client: null,
            error: '',
            data: {},

            waiting_for_response: false,
        };

        this.submit = this.submit.bind(this);
        this.login_callback = this.login_callback.bind(this);
        this.google_login = this.google_login.bind(this);
        this.google_error = this.google_error.bind(this);
    }

    componentDidMount() {
        let client = window.google.accounts.oauth2.initTokenClient({
            client_id: GOOGLE_CLIENT_ID,
            scope: 'email',
            callback: (response) => this.google_login(response),
            error_callback: (response) => this.google_error(response),
        });

        this.setState({ client: client });
    }

    submit(state) {
        let data = {
            email: state['email'],
            password: 'lowermytaxes',
            property_id: localStorage.getItem('property_id'),
        };
        data.email = data.email.toLowerCase();
        //console.log("data", data)

        ajax_wrapper('POST', '/user/register/', data, this.login_callback);
    }

    login_callback(value) {
        if ('error' in value) {
            if (value.error === 'Invalid Credentials') {
                this.setState({
                    error: (
                        <p>
                            Wrong Email or Password. If this is your first time
                            logging in, you may need to{' '}
                            <a href="/password_reset_request/">
                                reset your password first.
                            </a>
                        </p>
                    ),
                });
            } else {
                this.setState({ error: value.error });
            }
        } else {
            localStorage.setItem('token', value.access);
            localStorage.setItem('refresh_token', value.refresh);
            localStorage.setItem('token_time', new Date());

            if (localStorage.getItem('redirect')) {
                let url = localStorage.getItem('redirect');
                localStorage.removeItem('redirect');
                window.location.href = url;
            } else {
                window.location.href = '/welcome/';
            }
        }
    }

    google_login(state) {
        if (state && state.access_token) {
            ajax_wrapper(
                'POST',
                '/user/google_login/',
                state,
                this.login_callback,
            );
        }
    }

    google_error(state) {
        console.log('Google Error', state);
        this.setState({ error: state['message'] });
    }

    render() {
        let error = null;
        if (this.state.error !== '') {
            error = <Alert type="danger">{this.state.error}</Alert>;
        }

        let google_button = null;
        if (this.state.client) {
            google_button = (
                <Button
                    onClick={() => this.state.client.requestAccessToken()}
                    style={{ border: '1px solid black' }}
                >
                    <div
                        style={{
                            marginRight: '10px',
                            display: 'inline-block',
                            lineHeight: '20px',
                        }}
                    >
                        {'Login with Google'}
                    </div>
                    <img
                        style={{
                            width: '20px',
                            display: 'inline-block',
                            verticalAlign: 'top',
                        }}
                        src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA"
                        alt=""
                    />
                </Button>
            );
        }

        return (
            <Container>
                <div className="row" style={{ marginTop: '50px' }}>
                    <div className="col-md-8 col-sm-12">
                        <h2>Let Us Help You Save Money On Your House</h2>
                        <h6>Available Now</h6>
                        <ul>
                            <li>
                                We help you appeal property taxes when you are being over-taxed.
                            </li>
                        </ul>

                        <h6>Coming Soon</h6>
                        <ul>
                            <li>
                                Reduce Your Home Insurance Costs
                            </li>
                            <li>
                                Reduce Your Bills
                            </li>
                            <li>
                                Know If Your Contractor Quotes Make Sense
                            </li>
                            <li>
                                Save Money On Your Mortgage
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4 col-sm-12">
                    

                        <Form submit={this.submit} submit_text="Submit">
                            <TextInput
                                name="email"
                                label="Email"
                                required={true}
                            />
                            
                        </Form>
                        {error}

                    </div>
                </div>
            </Container>
        );
    }
}

export default SignUp;
