import {
    AdminRoutes,
    AuthedRoutes,
    AnonymousRoutes,
    Login,
    Logout,
    AdminDashboard,
    ObjectList,
    ManageObject,
    LoadLastURL,
    CurrentNotOffered,
    PrivacyPolicy,
    SignUp,
    Home,
    PropertyComparison,
    PropertyComparisonTwo,
    GoogleLogin,
    TaxMap,
    Welcome,
    AIBlogPost,
} from 'pages';

const routes = [
    {
        path: 'logout',
        element: <Logout />,
    },
    {
        path: 'login',
        element: <TaxMap />,
    },

    {
        path: 'load_last_url',
        element: <LoadLastURL />,
    },
    {
        path: '',
        element: <AnonymousRoutes />,
        children: [
            {
                path: 'google_login',
                element: <GoogleLogin />,
            },
            {
                path: 'property_comparison/:property_id',
                element: <PropertyComparisonTwo />,
            },
            {
                path: 'property_comparison_two/:property_id',
                element: <PropertyComparisonTwo />,
            },
            {
                path: 'tax_map',
                element: <TaxMap />,
            },
            {
                path: '',
                element: <TaxMap />,
            },
            {
                path: 'sign_up',
                element: <SignUp />,
            },
            {
                path: 'property_tax_guides/:state/:county',
                element: <AIBlogPost />,
            },
            {
                path: 'property_tax_guides/:state/:county/:city',
                element: <AIBlogPost />,
            },
        ],
    },
    {
        path: '',
        element: <AuthedRoutes />,
        children: [
            {
                path: 'welcome',
                element:<Welcome />,
            }
            
        ],
    },
    {
        path: 'admin',
        element: <AdminRoutes />,
        children: [
            
        ],
    },
];

export default routes;
