import { Container } from 'library';
import React, { Component } from 'react';

export default class Home extends Component {
    constructor(props) {
        super(props);
    }


    render() {

        return (
            <Container>
                <h1>Thanks for joining! We'll be sending you an email shortly to get you started on saving money.</h1>
            </Container>
        );
    }
}
