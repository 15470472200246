import React, { Component } from 'react';
import { Alert } from 'library';
import { Address } from 'pages';

import { DOLLAR_STYLING } from 'constants';

export default class ComparablePropertyDetailsCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let comparable = this.props.comparable;
        let comparable_property = comparable.comparable_property;
        let comparable_evaluation = comparable_property.tax_evaluation;

        let comparable_total =
            comparable.comparable_building_assessment +
            comparable.comparable_land_assessment +
            comparable_evaluation.exemption;

        return (
            <div className="simple-card full-height" style={this.props.style}>
                <Address
                    property={comparable_property}
                    vertical={true}
                    comparable={true}
                />
                <br />
                <Alert type="warning" style={{ margin: '10px 0px 0px 0px' }}>
                    <div style={{ fontWeight: 'bold' }}>Property Details</div>
                </Alert>
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <b>Neighborhood:</b>{' '}
                                {comparable_property.neighborhood}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Year Built:</b>{' '}
                                {comparable_property.year_built}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Sq. Ft.:</b> {comparable_property.sqft}{' '}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Acreage:</b> {comparable_property.acreage}
                            </td>
                        </tr>

                        <tr>
                            <th>Tax Details</th>
                        </tr>

                        <tr>
                            <td>
                                <b>$/Sq. Ft.:</b>{' '}
                                {(
                                    comparable_evaluation.building_assessment /
                                    comparable_property.sqft
                                ).toLocaleString('en-US', DOLLAR_STYLING)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>$/Acre:</b>{' '}
                                {(
                                    comparable_evaluation.land_assessment /
                                    comparable_property.acreage
                                ).toLocaleString('en-US', DOLLAR_STYLING)}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Alert type="warning" style={{ margin: '10px 0px 0px 0px' }}>
                    <div style={{ fontWeight: 'bold' }}>Current Assessment</div>
                </Alert>
                <table className="table">
                    <tbody>
                        <tr>
                            <td>
                                <b>Building Assessed Value:</b>{' '}
                                {comparable.comparable_building_assessment.toLocaleString(
                                    'en-US',
                                    DOLLAR_STYLING,
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Land Assessed Value:</b>{' '}
                                {comparable.comparable_land_assessment.toLocaleString(
                                    'en-US',
                                    DOLLAR_STYLING,
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Exemption:</b>{' '}
                                {comparable_evaluation.exemption.toLocaleString(
                                    'en-US',
                                    DOLLAR_STYLING,
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Total Assessed Value:</b>{' '}
                                {comparable_total.toLocaleString(
                                    'en-US',
                                    DOLLAR_STYLING,
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Yearly Taxes:</b>{' '}
                                {(comparable_total * 0.0224).toLocaleString(
                                    'en-US',
                                    DOLLAR_STYLING,
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}
