import React from 'react';

const HobokenTaxes = () => {
  return (
    <div className="container py-4">
      <h1 className="display-4 mb-4" style={{ color: '#2C3E50' }}>
        Hoboken Property Taxes: Complete 2024 Guide
      </h1>
      
      <div className="row mb-5">
        <div className="col-12">
          <p className="lead">
            Hoboken's property tax landscape reflects its unique position as a prime real estate market 
            within <a href='/property_tax_guides/nj/hudson_county' target='_blank'>Hudson County</a>. Despite having one of the lower tax rates in the county at 1.41%, 
            high property values mean significant tax bills for many homeowners.
          </p>
        </div>
      </div>

      <section className="mb-5">
        <h2 className="h3 mb-3" style={{ color: '#34495E' }}>Current Tax Rates & Trends</h2>
        
        <div className="row">
          <div className="col-md-8">
            <div className="mb-4">
              <h3 className="h5 text-primary">2024 Rate Breakdown</h3>
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between">
                  Municipal Purpose Tax
                  <span>0.71%</span>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  School Tax
                  <span>0.42%</span>
                </li>
                <li className="list-group-item d-flex justify-content-between">
                  County Tax
                  <span>0.28%</span>
                </li>
                <li className="list-group-item d-flex justify-content-between fw-bold">
                  Total Effective Rate
                  <span>1.41%</span>
                </li>
              </ul>
            </div>
            
            <p>
              Recent city council discussions have focused on maintaining stable tax rates while funding 
              infrastructure improvements, particularly flood mitigation projects and school system 
              enhancements.
            </p>
          </div>
          
          <div className="col-md-4">
            <div className="card border-primary">
              <div className="card-header bg-primary text-white">
                Example Tax Calculation
              </div>
              <div className="card-body">
                <p className="card-text">
                  Median Condo Value: $750,000<br />
                  Tax Rate: 1.41%<br />
                  <strong>Annual Tax: $10,575</strong><br />
                  Monthly Payment: $881.25
                </p>
                <small className="text-muted">Based on current median values</small>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-5">
        <h2 className="h3 mb-3" style={{ color: '#34495E' }}>Tax Exemptions & Abatements</h2>
        
        <div className="row g-4">
          <div className="col-md-6">
            <div className="card h-100">
              <div className="card-body">
                <h3 className="h5 card-title text-primary">Standard Exemptions</h3>
                <ul className="list-unstyled">
                  <li className="mb-2">✓ Veterans Deduction ($250 annually)</li>
                  <li className="mb-2">✓ Disabled Veterans (100% exemption)</li>
                  <li className="mb-2">✓ Senior Citizens (Up to $250 annually)</li>
                  <li className="mb-2">✓ Surviving Spouse Benefits</li>
                </ul>
              </div>
              <div className="card-footer bg-light">
                <small>Apply through the Tax Assessor's Office at City Hall</small>
              </div>
            </div>
          </div>
          
          <div className="col-md-6">
            <div className="card h-100">
              <div className="card-body">
                <h3 className="h5 card-title text-primary">PILOT Programs</h3>
                <p>
                  Hoboken offers Payment in Lieu of Taxes (PILOT) programs for specific developments:
                </p>
                <ul className="list-unstyled">
                  <li className="mb-2">✓ Western Edge Redevelopment Area</li>
                  <li className="mb-2">✓ Northwest Redevelopment Zone</li>
                  <li className="mb-2">✓ Southwest Rehabilitation Area</li>
                </ul>
              </div>
              <div className="card-footer bg-light">
                <small>Terms vary by development agreement</small>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-5">
        <h2 className="h3 mb-4" style={{ color: '#34495E' }}>Tax Appeals in Hoboken</h2>
        
        <div className="card mb-4">
          <div className="card-body">
            <h3 className="h5 text-primary">Appeal Process & Statistics</h3>
            <div className="row">
              <div className="col-md-6">
                <p>2023 Appeal Statistics:</p>
                <ul>
                  <li>Total Appeals Filed: ~1,200</li>
                  <li>Success Rate: 35%</li>
                  <li>Average Assessment Reduction: $85,000</li>
                  <li>Average Tax Savings: $1,198 annually</li>
                </ul>
              </div>
              <div className="col-md-6">
                <p>Key Deadlines:</p>
                <ul>
                  <li>Appeal Filing: April 1st</li>
                  <li>Added Assessment Appeals: December 1st</li>
                  <li>Revaluation Response: 45 days from notice</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h3 className="h5 text-primary">Recent Success Story</h3>
            <p>
              A notable 2023 case involved a Washington Street condominium owner who successfully 
              appealed their assessment from $950,000 to $875,000. The owner demonstrated that 
              similar units in their building sold for less than the assessed value, resulting 
              in annual tax savings of approximately $1,060.
            </p>
            <p className="mb-0">
              The case highlighted the importance of researching comparable sales within the same 
              building or immediate neighborhood when preparing an appeal.
            </p>
          </div>
        </div>
      </section>

      <section className="mb-5">
        <h2 className="h3 mb-3" style={{ color: '#34495E' }}>Neighborhood Variations</h2>
        
        <div className="table-responsive">
          <table className="table table-striped">
            <thead className="table-primary">
              <tr>
                <th>Neighborhood</th>
                <th>Median Assessment</th>
                <th>Average Annual Tax</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Historic Downtown</td>
                <td>$850,000</td>
                <td>$11,985</td>
              </tr>
              <tr>
                <td>Waterfront</td>
                <td>$1,200,000</td>
                <td>$16,920</td>
              </tr>
              <tr>
                <td>Upper Grand</td>
                <td>$725,000</td>
                <td>$10,223</td>
              </tr>
              <tr>
                <td>Western Edge</td>
                <td>$650,000</td>
                <td>$9,165</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <footer className="border-top pt-4">
        <div className="row">
          <div className="col-12">
            <p className="text-muted">
              <small>
                Information current as of 2024. Tax rates and exemption criteria are subject to change. 
                For the most current information, please contact the Hoboken Tax Assessor's Office at City Hall. 
                Assessment values vary by property and neighborhood.
              </small>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HobokenTaxes;