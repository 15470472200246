import React from 'react';

const UnionCityTaxes = () => {
  return (
    <div className="container py-4">
      <h1 className="display-4 mb-4" style={{ color: '#2C3E50' }}>
        Union City Property Taxes: Complete Guide 2024
      </h1>
      
      <div className="row mb-5">
        <div className="col-12">
          <p className="lead">
            Union City, located in <a href='/property_tax_guides/nj/hudson_county' target='_blank'>Hudson County</a>, has seen significant changes in its property tax 
            landscape over recent years. This guide covers current rates, exemptions, and essential 
            information for property owners in this densely populated urban area.
          </p>
        </div>
      </div>

      <section className="mb-5">
        <h2 className="h3 mb-3" style={{ color: '#34495E' }}>Current Tax Rates and Trends</h2>
        
        <div className="row">
          <div className="col-md-8">
            <p>
              Union City's effective property tax rate is approximately 1.89% as of 2024, slightly 
              above the <a href='/property_tax_guides/nj/hudson_county' target='_blank'>Hudson County</a> average. The city has worked to stabilize rates through urban 
              development initiatives and careful budget management.
            </p>
            
            <div className="card mb-4 border-0 bg-light">
              <div className="card-body">
                <h3 className="h5 text-primary">Recent Changes</h3>
                <ul className="mb-0">
                  <li>Modest increase from 2023's rate of 1.86%</li>
                  <li>New ratables from development projects helping to stabilize rates</li>
                  <li>Urban Enterprise Zone benefits affecting commercial properties</li>
                </ul>
              </div>
            </div>
          </div>
          
          <div className="col-md-4">
            <div className="card border-primary">
              <div className="card-header bg-primary text-white">
                Tax Calculation Example
              </div>
              <div className="card-body">
                <p className="card-text">
                  Property Value: $400,000<br />
                  Tax Rate: 1.89%<br />
                  <strong>Annual Tax: $7,560</strong><br />
                  Monthly Payment: $630
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-5">
        <h2 className="h3 mb-3" style={{ color: '#34495E' }}>Local Tax Relief Programs</h2>
        
        <div className="row g-4">
          <div className="col-md-6">
            <div className="card h-100">
              <div className="card-body">
                <h3 className="h5 card-title text-primary">Union City Specific Programs</h3>
                <ul className="list-unstyled">
                  <li className="mb-2">✓ City tax abatement for new construction</li>
                  <li className="mb-2">✓ Home improvement exemption program</li>
                  <li className="mb-2">✓ Urban Enterprise Zone benefits</li>
                  <li className="mb-2">✓ First-time homebuyer assistance</li>
                </ul>
                <p className="mt-3">
                  <small>Programs require application through City Hall</small>
                </p>
              </div>
            </div>
          </div>
          
          <div className="col-md-6">
            <div className="card h-100">
              <div className="card-body">
                <h3 className="h5 card-title text-primary">Standard Exemptions</h3>
                <ul className="list-unstyled">
                  <li className="mb-2">✓ Senior Citizen Deduction ($250)</li>
                  <li className="mb-2">✓ Veterans Deduction ($250)</li>
                  <li className="mb-2">✓ Disabled Veterans Exemption (100%)</li>
                  <li className="mb-2">✓ Disabled Person Deduction ($250)</li>
                </ul>
                <p className="mt-3">
                  <small>Income limits and residency requirements apply</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-5">
        <h2 className="h3 mb-4" style={{ color: '#34495E' }}>Property Tax Appeals</h2>
        
        <div className="row">
          <div className="col-12">
            <div className="card mb-4">
              <div className="card-body">
                <h3 className="h5 text-primary">Appeal Statistics</h3>
                <p>
                  Union City processes approximately 800-1,000 tax appeals annually, with a success rate 
                  of about 35%. The majority of successful appeals result in assessment reductions between 
                  5% and 15%.
                </p>
                <div className="row mt-4">
                  <div className="col-md-4 mb-3">
                    <div className="border-start border-primary border-4 ps-3">
                      <h4 className="h6">Annual Appeals</h4>
                      <p className="h4 mb-0">~900</p>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="border-start border-primary border-4 ps-3">
                      <h4 className="h6">Success Rate</h4>
                      <p className="h4 mb-0">35%</p>
                    </div>
                  </div>
                  <div className="col-md-4 mb-3">
                    <div className="border-start border-primary border-4 ps-3">
                      <h4 className="h6">Average Reduction</h4>
                      <p className="h4 mb-0">10%</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <h3 className="h5 text-primary">Recent Success Story</h3>
                <p>
                  In a notable 2023 case, a Summit Avenue property owner successfully appealed their 
                  assessment, resulting in a $125,000 reduction in assessed value. The owner demonstrated 
                  that recent sales of comparable properties in the neighborhood supported a lower valuation.
                </p>
                <p className="mb-0">
                  The appeal process took approximately 2.5 months and resulted in annual tax savings 
                  of $2,362. The case highlighted the importance of thorough market research and 
                  proper documentation in the appeal process.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-5">
        <h2 className="h3 mb-3" style={{ color: '#34495E' }}>Important Dates</h2>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-6">
                <h4 className="h6 text-primary">Tax Payment Due Dates</h4>
                <ul className="list-unstyled">
                  <li>✓ 1st Quarter: February 1st</li>
                  <li>✓ 2nd Quarter: May 1st</li>
                  <li>✓ 3rd Quarter: August 1st</li>
                  <li>✓ 4th Quarter: November 1st</li>
                </ul>
              </div>
              <div className="col-md-6">
                <h4 className="h6 text-primary">Appeal Deadlines</h4>
                <ul className="list-unstyled">
                  <li>✓ Standard Appeals: April 1st</li>
                  <li>✓ Added/Omitted Appeals: December 1st</li>
                  <li>✓ Revaluation Appeals: 45 days from notice</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="border-top pt-4">
        <div className="row">
          <div className="col-12">
            <p className="text-muted">
              <small>
                Information current as of 2024. Tax rates and program eligibility criteria are 
                subject to change. Contact the Union City Tax Assessor's Office for the most 
                current information and specific details about your property.
              </small>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default UnionCityTaxes;