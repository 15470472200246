import React, { Component } from 'react';

export default class AssessedValue extends Component {
    constructor(props) {
        super(props);
    }


    render() {

        let property = this.props.property;
        let evaluation = this.props.evaluation;

        return (
            <div>
                <h4>Property Tax Details</h4>
                <table className="table">
                    <tbody>
                        <tr>
                            <td><b>Building Assessed Value:</b> ${evaluation.building_assessment}</td>
                            <td><b>Sq. Ft.:</b> {property.sqft}</td>
                            <td><b>$/Sqft:</b>${(evaluation.building_assessment/property.sqft).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td><b>Land Assessed Value:</b> ${evaluation.land_assessment}</td>
                            <td><b>Acreage:</b> {property.acreage}</td>
                            <td><b>$/Acre:</b> ${(evaluation.land_assessment/property.acreage).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <td><b>Exemption:</b> ${evaluation.exemption}</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><b>Total Assessed Value:</b> ${evaluation.building_assessment + evaluation.land_assessment + evaluation.exemption}</td>
                            <td><b>Yearly Taxes:</b> ${property.current_taxes.toFixed(2)}</td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>

        );
    }
}
