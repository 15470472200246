import React, { Component } from 'react';

import { ajax_wrapper, get_global_state } from 'functions';
import { Loading } from 'library';

class GoogleLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];
        localStorage.setItem('token', params['access']);
        localStorage.setItem('refresh_token', params['refresh']);
        localStorage.setItem('token_time', new Date());

        window.location.href = '/accounts_and_properties/';
    }

    render() {
        return <Loading loaded={this.state.loaded}></Loading>;
    }
}

export default GoogleLogin;
