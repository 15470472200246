import React, { Component } from 'react';
import { Address, AssessedValue } from 'pages';

export default class ComparableCard extends Component {

    render() {
        let comparable_evaluation = this.props.comparable;
        let comparable_property = this.props.comparable.comparable_property;

        let savings = (this.props.evaluation['building_assessment'] + this.props.evaluation['land_assessment'] - comparable_evaluation['comparable_total_valuation']) * .0224

        return (
            <div className='card col-md-5 col-sm-10' style={{padding:'10px', margin:'10px'}}>
                <Address property={comparable_property} vertical={true} />
                <div style={{height:'50px'}}></div>
                <AssessedValue property={comparable_property} evaluation={comparable_property.tax_evaluation} />
                <p>If your property was taxed the same way, <b style={{backgroundColor:'yellow'}}>you'd save ${savings.toFixed(0)} a year!</b></p>
            </div>

        );
    }
}
