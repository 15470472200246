import React, { Component } from 'react';
import { ajax_wrapper, get_global_state } from 'functions';
import { Loading, Container, Button } from 'library';
import { Address, AssessedValue, ComparableCard } from 'pages';
import {Helmet} from "react-helmet";

export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            'property': {},
            'comparables': [],
            loaded: false,
        }

        this.get_property = this.get_property.bind(this);
        this.load_property = this.load_property.bind(this);
    }

    componentDidMount() {
        this.get_property();
    }

    get_property() {
        let params = get_global_state()['params']
        localStorage.setItem('property_id', params['property_id']);
        ajax_wrapper('GET', '/api/property_insights/?property_id=' + params['property_id'], {}, this.load_property)
    }

    load_property(data) {
        data['loaded'] = true;
        this.setState(data)
    }


    render() {
        let comparable_list = []
        for (let comparable of this.state.comparables) {
            comparable_list.push(<ComparableCard comparable={comparable} property={this.state.property} evaluation={this.state.property.tax_evaluation} />)
        }

        let property_address = null;
        if (this.state.property) {
            let property = this.state.property;
            if (property.address2 != '') {
                property_address = property.address + ' ' + property.address2 + ', ' + property.city + ', ' + property.state + ' ' + property.zipcode;
            }
            else {
                property_address = property.address + ', ' + property.city + ', ' + property.state + ' ' + property.zipcode;
            }
        }

        return (
            <Container>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{property_address} Property Tax Comparison</title>
                    <meta name="description" content={"A comparison of " + property_address + " and similar properties and their property tax rates."} />
                </Helmet>
                <Loading loaded={this.state.loaded}>
                    <Address property={this.state.property} />
                    <div style={{height:'50px'}}></div>
                    <AssessedValue property={this.state.property} evaluation={this.state.property.tax_evaluation} />
                    <div style={{height:'50px'}}></div>
                    <h4>Comparables</h4>
                    <p>These are similar houses in your area that are taxed more favorably and outline our case for lowering your tax rate.</p>
                    <Button type='success' href='/sign_up'>Help Me Lower My Taxes</Button>
                    <div className='row'>
                        {comparable_list}
                    </div>
                </Loading>
            </Container>

        );
    }
}
