import React from 'react';

const WeehawkenTaxes = () => {
  return (
    <div className="container py-4">
      <h1 className="display-4 mb-4" style={{ color: '#2C3E50' }}>
        Weehawken Property Taxes: 2024 Guide
      </h1>
      
      <div className="row mb-5">
        <div className="col-12">
          <p className="lead">
            Weehawken, known for its stunning NYC views and waterfront developments, 
            has a unique property tax landscape shaped by significant development and 
            PILOT programs. Understanding the tax structure is crucial for current and 
            prospective property owners.
          </p>
        </div>
      </div>

      <section className="mb-5">
        <h2 className="h3 mb-3" style={{ color: '#34495E' }}>Current Tax Rates and PILOT Programs</h2>
        
        <div className="row">
          <div className="col-md-8">
            <p>
              Weehawken's effective tax rate is currently 1.82%, slightly above the 
              <a href='/property_tax_guides/nj/hudson_county' target='_blank'>Hudson County</a> average. However, many newer developments operate under 
              PILOT (Payment in Lieu of Taxes) agreements, which can significantly 
              affect actual payments.
            </p>
            
            <div className="card mb-3 border-info">
              <div className="card-header bg-info text-white">
                Notable PILOT Areas
              </div>
              <div className="card-body">
                <ul className="list-unstyled mb-0">
                  <li className="mb-2">✓ Port Imperial Waterfront Development</li>
                  <li className="mb-2">✓ Lincoln Harbor District</li>
                  <li className="mb-2">✓ The Brownstone District</li>
                  <li>✓ Harbor Boulevard Developments</li>
                </ul>
              </div>
            </div>
          </div>
          
          <div className="col-md-4">
            <div className="card border-primary">
              <div className="card-header bg-primary text-white">
                Standard Tax Example
              </div>
              <div className="card-body">
                <p className="card-text">
                  Property Value: $500,000<br />
                  Tax Rate: 1.82%<br />
                  <strong>Annual Tax: $9,100</strong><br />
                  Monthly Payment: $758.33
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-5">
        <h2 className="h3 mb-3" style={{ color: '#34495E' }}>Tax Relief and Exemptions</h2>
        
        <div className="row g-4">
          <div className="col-md-6">
            <div className="card h-100">
              <div className="card-body">
                <h3 className="h5 card-title text-primary">Residential Benefits</h3>
                <ul className="list-unstyled">
                  <li className="mb-2">✓ Veterans Deduction: $250 annual</li>
                  <li className="mb-2">✓ Senior Citizen Deduction (65+): Up to $250</li>
                  <li className="mb-2">✓ Disabled Person Deduction: $250</li>
                  <li className="mb-2">✓ Longtime Resident Protection Program</li>
                </ul>
                <div className="mt-3">
                  <small className="text-muted">
                    Income limits and residency requirements apply
                  </small>
                </div>
              </div>
            </div>
          </div>
          
          <div className="col-md-6">
            <div className="card h-100">
              <div className="card-body">
                <h3 className="h5 card-title text-primary">Special Programs</h3>
                <ul className="list-unstyled">
                  <li className="mb-2">✓ PILOT Payment Programs</li>
                  <li className="mb-2">✓ Tax Abatement Zones</li>
                  <li className="mb-2">✓ Home Improvement Exemption</li>
                  <li className="mb-2">✓ New Construction Incentives</li>
                </ul>
                <div className="mt-3">
                  <small className="text-muted">
                    Contact Tax Assessor's office for program details
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-5">
        <h2 className="h3 mb-4" style={{ color: '#34495E' }}>Property Assessment and Appeals</h2>
        
        <div className="row">
          <div className="col-12">
            <div className="card mb-4">
              <div className="card-body">
                <h3 className="h5 text-primary">Assessment Overview</h3>
                <p>
                  Weehawken conducts regular property reassessments to maintain fair market 
                  valuations. The township's last comprehensive revaluation was completed 
                  in 2021, affecting approximately 6,000 properties.
                </p>
                <ul>
                  <li>Average assessment increase: 15% (2021 revaluation)</li>
                  <li>Typical appeal timeline: 3-4 months</li>
                  <li>Annual appeal deadline: April 1st</li>
                </ul>
              </div>
            </div>

            <div className="card">
              <div className="card-body">
                <h3 className="h5 text-primary">Recent Appeal Success Story</h3>
                <p>
                  A notable 2023 case involved a Weehawken waterfront condominium owner 
                  who successfully appealed their assessment. The property's assessed 
                  value was reduced from $1.2 million to $950,000, resulting in annual 
                  tax savings of approximately $4,550.
                </p>
                <p className="mb-0">
                  Key factors in the successful appeal included:
                </p>
                <ul className="mb-0">
                  <li>Recent sales data from comparable units</li>
                  <li>Documentation of market conditions</li>
                  <li>Professional appraisal support</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mb-5">
        <h2 className="h3 mb-3" style={{ color: '#34495E' }}>Neighborhood Tax Variations</h2>
        
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-hover">
                <thead className="table-light">
                  <tr>
                    <th>Neighborhood</th>
                    <th>Avg. Assessment</th>
                    <th>Typical Annual Tax*</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Waterfront</td>
                    <td>$1,200,000</td>
                    <td>$21,840</td>
                  </tr>
                  <tr>
                    <td>Upper Weehawken</td>
                    <td>$650,000</td>
                    <td>$11,830</td>
                  </tr>
                  <tr>
                    <td>King's Bluff</td>
                    <td>$850,000</td>
                    <td>$15,470</td>
                  </tr>
                  <tr>
                    <td>Downtown</td>
                    <td>$550,000</td>
                    <td>$10,010</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <small className="text-muted">*Based on current tax rate of 1.82%</small>
          </div>
        </div>
      </section>

      <footer className="border-top pt-4">
        <div className="row">
          <div className="col-12">
            <p className="text-muted">
              <small>
                Information current as of 2024. Tax rates, PILOT agreements, and program 
                eligibility criteria are subject to change. Contact the Weehawken Tax 
                Assessor's Office for the most current information and specific details 
                about your property or potential purchase.
              </small>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default WeehawkenTaxes;