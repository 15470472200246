const HEADER_HEIGHT = 60;

const DOLLAR_STYLING = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
};

export { HEADER_HEIGHT, DOLLAR_STYLING };
