import React, { Component } from 'react';
import { get_global_state } from 'functions';
import {Helmet} from "react-helmet";
import {BlogCTA} from 'pages';

import {default as JerseyCity} from './cities/jersey_city.js';
import {default as Hoboken} from './cities/hoboken.js';
import {default as UnionCity} from './cities/union_city.js';
import {default as Weehawken} from './cities/weehawken.js';

import {default as HudsonCounty} from './counties/hudson_county.js';

export default class AIBlogPost extends Component {
    constructor(props) {
        super(props);
    }

    toTitleCase(str) {
      str = str.replaceAll('_', ' ')
      return str.replace(
        /\w\S*/g,
        text => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
      );
    }

    render() {

      let county_dict = {
        'hudson_county': <HudsonCounty />,
      }

      let city_dict = {
        'jersey_city': <JerseyCity />,
        'union_city': <UnionCity />,
        'hoboken': <Hoboken />,
        'weehawken': <Weehawken />,
      }

      let params = get_global_state()['params'];
      let county = params['county']
      let city = params['city']
      console.log("County", county)
      console.log("City", city)

      let article = null;
      let title = '';
      let description = '';
      if (city) {
        article = city_dict[city]
        title = "Comprehensive Guide To Property Taxes For " + this.toTitleCase(city) + ", " + this.toTitleCase(county) + ", NJ."
        description = "An article that details the current property tax, future possible updates, exemptions and ways to appeal property tax in " + this.toTitleCase(city) + ", " + this.toTitleCase(county) + ", NJ." 
      }
      else if (county) {
        article = county_dict[county];
        title = "Comprehensive Guide To Property Taxes For " + this.toTitleCase(county) + ", NJ."
        description = "An article that details the current property tax, future possible updates, exemptions and ways to appeal property tax in " + this.toTitleCase(county) + ", NJ."
      }
      else {
        article = <div className="container"><p>I'm sorry, we could not find this article.</p></div>
      }
    
        
        return (
          <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="description" content={description} />
            </Helmet>
            {article}
            <BlogCTA />
          </div>
        );
    }
}
