import React, { Component } from 'react';
import { Button, Alert } from 'library';

import { DOLLAR_STYLING } from 'constants';

function deg_to_rad(deg) {
    return deg * (Math.PI / 180);
}

function get_distance(lat1, lon1, lat2, lon2) {
    var R = 3958.8; // Radius of the earth in miles
    var dLat = deg_to_rad(lat2 - lat1); // deg2rad below
    var dLon = deg_to_rad(lon2 - lon1);
    var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg_to_rad(lat1)) *
            Math.cos(deg_to_rad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c; // Distance in km
    return d;
}

export default class TotalDetailsCard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let property = this.props.property;
        let evaluation = property.tax_evaluation;

        let comparable = this.props.comparable;
        let comparable_property = comparable.comparable_property;
        let comparable_evaluation = comparable_property.tax_evaluation;

        let current_total =
            evaluation.building_assessment +
            evaluation.land_assessment +
            evaluation.exemption;
        let comparable_total =
            comparable.comparable_building_assessment +
            comparable.comparable_land_assessment +
            evaluation.exemption;
        let savings = (current_total - comparable_total) * 0.0224;

        let sqft_compare_color = 'green';
        if (
            comparable_evaluation.building_assessment /
                comparable_property.sqft >
            evaluation.building_assessment / property.sqft
        ) {
            sqft_compare_color = 'red';
        }

        let acre_compare_color = 'green';
        if (
            comparable_evaluation.land_assessment /
                comparable_property.acreage >
            evaluation.land_assessment / property.acreage
        ) {
            acre_compare_color = 'red';
        }

        let distance_between = get_distance(
            property.latitude,
            property.longitude,
            comparable_property.latitude,
            comparable_property.longitude,
        );

        return (
            <div className="simple-card full-height">
                <div
                    style={{
                        margin: '-10px -20px 0px -20px',
                        position: 'relative',
                    }}
                >
                    <div
                        className="background-image"
                        style={{
                            backgroundImage: 'url(/static/images/dollars.jpg)',
                        }}
                    ></div>
                </div>
                <br />
                <Alert type="success" style={{ margin: '10px 0px 0px 0px' }}>
                    <div style={{ fontWeight: 'bold' }}>Compared To You</div>
                </Alert>
                <table className="table">
                    <tbody>
                        <tr>
                            <td style={{ color: 'green' }}>
                                {distance_between.toFixed(2)} miles away
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'green' }}>
                                {property.year_built -
                                    comparable_property.year_built}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'green' }}>
                                {(
                                    (property.sqft / comparable_property.sqft) *
                                    100
                                ).toFixed(0)}
                                %
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'green' }}>
                                {(
                                    (property.acreage /
                                        comparable_property.acreage) *
                                    100
                                ).toFixed(0)}
                                %
                            </td>
                        </tr>

                        <tr>
                            <th>Tax Details</th>
                        </tr>

                        <tr>
                            <td style={{ color: 'green' }}>
                                {(
                                    (comparable_evaluation.building_assessment /
                                        comparable_property.sqft /
                                        (evaluation.building_assessment /
                                            property.sqft)) *
                                    100
                                ).toFixed(0)}
                                %
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'green' }}>
                                {(
                                    (comparable_evaluation.land_assessment /
                                        comparable_property.acreage /
                                        (evaluation.land_assessment /
                                            property.acreage)) *
                                    100
                                ).toFixed(0)}
                                %
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Alert type="success" style={{ margin: '10px 0px 0px 0px' }}>
                    <div style={{ fontWeight: 'bold' }}>
                        If Your Home Was Taxed The Same Way
                    </div>
                </Alert>
                <table className="table">
                    <tbody>
                        <tr>
                            <td style={{ color: 'green' }}>
                                {(
                                    evaluation.building_assessment -
                                    comparable.comparable_building_assessment
                                ).toLocaleString('en-US', DOLLAR_STYLING)}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'green' }}>
                                {(
                                    evaluation.land_assessment -
                                    comparable.comparable_land_assessment
                                ).toLocaleString('en-US', DOLLAR_STYLING)}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <b>Exemption:</b>{' '}
                                {evaluation.exemption.toLocaleString(
                                    'en-US',
                                    DOLLAR_STYLING,
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'green' }}>
                                {(
                                    current_total - comparable_total
                                ).toLocaleString('en-US', DOLLAR_STYLING)}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: 'green' }}>
                                {savings.toLocaleString(
                                    'en-US',
                                    DOLLAR_STYLING,
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Alert type="success">
                    {`If your property was taxed the same way, you'd save `}
                    <b>{`$${savings.toFixed(0)} a year!`}</b>
                </Alert>
                <Button
                    type="success"
                    href="/sign_up"
                    style={{ fontSize: '16px' }}
                >
                    Lower My Property Taxes
                </Button>
            </div>
        );
    }
}
