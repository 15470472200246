import React, { Component } from 'react';
import $, { ajax } from 'jquery';

import { HEADER_HEIGHT } from 'constants';

import { Loading, Container, Button } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

class UserChoice extends Component {
    constructor(props) {
        super(props);

        this.choose_user = this.choose_user.bind(this);
    }

    choose_user() {
        ajax_wrapper(
            'POST',
            '/user/choose_user/',
            { user_id: this.props.user.id },
            () => (window.location.href = '/insights/'),
        );
    }

    render() {
        return (
            <tr>
                <td>{this.props.user.email}</td>
                <td>
                    <Button type="primary" onClick={this.choose_user}>
                        See This Users Report
                    </Button>
                </td>
            </tr>
        );
    }
}

export default class Ghost extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            loaded: false,
        };

        this.get_users = this.get_users.bind(this);
        this.load_users = this.load_users.bind(this);
    }

    componentDidMount() {
        this.get_users();
    }

    get_users() {
        ajax_wrapper('GET', '/user/get_all_users/', {}, this.load_users);
    }

    load_users(data) {
        data['loaded'] = true;
        this.setState(data);
    }

    render() {
        let user_list = [];
        for (let user of this.state.users) {
            user_list.push(<UserChoice user={user} />);
        }

        return (
            <Loading loaded={this.state.loaded}>
                <Container>
                    <h3>Find The User To Help</h3>
                    <div style={{ height: '50px' }}></div>
                    <table className="table">
                        <thead>
                            <th>User Email</th>
                            <th>Ghost As User</th>
                        </thead>
                        <tbody>{user_list}</tbody>
                    </table>
                </Container>
            </Loading>
        );
    }
}
