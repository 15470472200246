import React, { Component } from 'react';
import { UserValidator } from 'functions';
import { isMobile } from 'react-device-detect';

class Footer extends Component {
    render() {
        //let checker = new UserValidator();
        //let is_logged_in = checker.logged_id();
        //let is_staff = checker.is_staff();
        //let no_footer = false;

        let footer = (
            <div className="container">
                <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
                    <p class="col-md-4 mb-0 text-body-secondary">
                        © 2024 HomeBase Inc. All rights reserved.
                    </p>

                    

                    <ul class="nav col-md-2 justify-content-end">
                        <li class="nav-item">
                            <a
                                href="https://www.homebase.today/privacy"
                                class="nav-link px-2 text-body-secondary"
                            >
                                Privacy Policy
                            </a>
                        </li>
                    </ul>

                    <ul class="nav col-md-4 justify-content-end">
                        <li class="nav-item">
                            <a
                                href="https://www.homebase.today/terms-of-service"
                                class="nav-link px-2 text-body-secondary"
                            >
                                Terms & Conditions
                            </a>
                        </li>
                    </ul>
                </footer>
            </div>
        );

        return footer;
    }
}

export default Footer;
