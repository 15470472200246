import React, { Component } from 'react';

export default class Address extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let property = this.props.property;
        let latlng = property.latitude + ',' + property.longitude;
        let img_url =
            'https://maps.googleapis.com/maps/api/streetview?location=' +
            latlng +
            '&size=600x400&key=AIzaSyCTeyTeeIoM-qDCe1Z8W7OK0qDVAJ8QzhU';

        let comparable_tag = this.props.comparable ? (
            <div className="floating-on-top">
                <span class="badge rounded-pill text-bg-warning custom-top-badge">
                    Comparable
                </span>
            </div>
        ) : null;

        if (this.props.vertical) {
            if (this.props.small) {
                return (
                    <div style={{ position: 'relative' }}>
                        <div
                            className="background-image"
                            style={{
                                backgroundImage: `url(${img_url})`,
                            }}
                        ></div>
                        <div className="classy-address">
                            <h4>{property.address}</h4>
                            <div>{property.address2}</div>
                            <div>
                                {property.city}, {property.state}{' '}
                                {property.zipcode}
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div
                        style={{
                            margin: '-10px -20px 0px -20px',
                            position: 'relative',
                        }}
                    >
                        <div
                            className="background-image"
                            style={{
                                backgroundImage: `url(${img_url})`,
                            }}
                        ></div>
                        <div className="classy-address">
                            <h4>{property.address}</h4>
                            <div>{property.address2}</div>
                            <div>
                                {property.city}, {property.state}{' '}
                                {property.zipcode}
                            </div>
                        </div>
                        {comparable_tag}
                    </div>
                );
            }
        } else {
            return (
                <div className="row">
                    <div className="col-4">
                        <img src={img_url} style={{ width: '100%' }} />
                    </div>
                    <div className="col-8">
                        <h4>{property.address}</h4>
                        <h4>{property.address2}</h4>
                        <h4>
                            {property.city}, {property.state} {property.zipcode}
                        </h4>
                    </div>
                </div>
            );
        }
    }
}
